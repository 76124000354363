import { KTIcon, KTSVG } from '../../../../_metronic/helpers'
import { Form } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { add_organisation_user, delete_organisation_user, get_organisation_users, update_organisation_user, verify_user } from '../api/OrganisationsApi';
import { toast } from 'react-toastify';
import { ChangeEvent, useEffect, useState } from 'react';
import { OrganisationListInterface, OrganisationUsersInterface } from '../../../modules/accounts/components/settings/SettingsModel';
import { useLocation, useNavigate } from 'react-router-dom';
import { log } from 'node:console';

function OrganisationUsers() {
    const [organistionUsers, setOrganisationUsers] = useState<OrganisationUsersInterface[]>([])
    const [selectedOganistionUser, setSelectedOrganisationUser] = useState<OrganisationUsersInterface>()
    const [searchInput, setSearchInput] = useState('');
    const [emailvalue, setEmailValue] = useState('')
    const [firstNameValue, setFirstNameValue] = useState('')
    const [middleNameValue, setMiddleNameValue] = useState('')
    const [lastNameValue, setLastNameValue] = useState('')
    const [isEditable, setIsEditable] = useState(false);
    const [mobileValue, setMobileValue] = useState('')
    const [userPicture, setUserPicture] = useState<File | null>(null);
    const [manageOrganisationValue, setManageOrganisationValue] = useState(0)
    const [manageApplicationValue, setManageApplicationValue] = useState(0)
    const location = useLocation();
    const organisation = (location.state as { organisation: OrganisationListInterface }).organisation
    const { data: usersData, refetch } = useQuery(['organisationUsers', organisation.id],
        () => get_organisation_users(organisation.id), {
        refetchOnWindowFocus: false
    }
    )
    const navigate = useNavigate();
    useEffect(() => {
        if (usersData) {
            setOrganisationUsers(usersData.data as OrganisationUsersInterface[])
        }
    }, [usersData])
    console.log(selectedOganistionUser);

    const verifyUserMutation = useMutation(verify_user, {
        onSuccess: (data: any) => {
            // Success: Set fetched data and show success toast
            setFirstNameValue(data.data.first_name);
            setMiddleNameValue(data.data.middle_name);
            setLastNameValue(data.data.last_name);
            setMobileValue(data.data.mobile_no);
            setUserPicture(data.data.profile_picture);
            toast.success(data?.message || 'Organisation added successfully!', {
                position: 'top-right',
                autoClose: 3000,
            });
        }, onError: (error: any) => {
            // Error: Show error toast
            const errorMessage =
                error?.response?.data?.message || error?.message || 'Something went wrong';
            toast.error(errorMessage, {
                position: 'top-right',
                autoClose: 3000,
            });
        },
    });
    const resetForm = () => {
        setEmailValue('');
        setFirstNameValue('');
        setMiddleNameValue('');
        setLastNameValue('');
        setMobileValue('');
        setUserPicture(null);
        setManageOrganisationValue(0);
        setManageApplicationValue(0);
    };
    const handleAddOrganisationUser = useMutation(add_organisation_user, {
        onSuccess: (data: any) => {
            // Success: Show success toast and refetch data
            toast.success(data.message, {
                position: 'top-right',
                autoClose: 3000,
            });
            refetch();
        },
        onError: (error: any) => {
            // Error: Show error toast
            const errorMessage =
                error?.response?.data?.message || error?.message || 'Something went wrong';
            toast.error(errorMessage, {
                position: 'top-right',
                autoClose: 3000,
            });
        },
    });

    const handleEditOrganisationUser = useMutation((Data: any) => update_organisation_user(Data.user_id, Data), {
        onSuccess: (response: any) => {
            refetch()
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
        },
        onError: (error: any) => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else if (error.request) {
                // The request was made but no response was received
                toast.error('No response received from the server.', {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                toast.error('An unexpected error occurred.', {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        },
    });
    const handleDeleteOrganisationUser = useMutation(delete_organisation_user, {
        onSettled: (data, error: any) => {
            if (!error) {
                refetch()
                toast.success(data.message, {
                    position: 'top-right',
                    autoClose: 3000,
                })
            } else {
                const errorMessage = error?.response?.data?.message || error?.message || 'Something went wrong';
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 3000,
                })
            }
        },
    })
    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const searchTerm = e.currentTarget.value;
        setSearchInput(searchTerm);
        const filteredItems = usersData.data.filter((users) => {
            return (users.user.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                users.user.email?.toLowerCase().includes(searchTerm.toLowerCase())
            )

        })

        setOrganisationUsers(filteredItems);
    };
    const HandleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setUserPicture(e.target.files[0]);
        };
    };

    return (
        <>
            <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10' style={{ position: 'relative' }}>
                <div className=''>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title d-flex justify-content-left'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute mx-6' />
                            <input
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Search a User"
                                type='text'
                                data-kt-user-table-filter='search'
                                className='form-control form-control-solid w-400px ps-14'
                                placeholder='Search a User'
                                value={searchInput}
                                onChange={handleInputChange}
                            />
                        </h3>
                        <div className='d-flex align-items-center'>
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-trigger='hover'
                            >
                                <button
                                    data-toggle="tooltip" data-placement="bottom" title="Navigate to List of Organisation"
                                    className='btn btn-sm btn-light-dark fs-5'
                                    onClick={() => navigate('/organisations')}
                                >
                                    Back to Organisations List
                                </button>
                            </div>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Navigate to Add User"
                                className='btn btn-sm btn-light-dark m-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_2'
                                onClick={() => {
                                    resetForm();
                                    setIsEditable(false);
                                }}
                            >
                                Add User
                            </button>
                        </div>
                    </div>
                </div>
                {/* User listing */}
                <div className='card-body mt-3'>
                    <h3 className='mb-3'>Users to Manage Organisation</h3>
                    <table className='table align-middle table-row-dashed fs-6 gy-5'>
                        <thead className='bg-dark'>
                            <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0 '>
                                <th className='max-w-125px px-4'>Name</th>
                                <th className='max-w-200px'>Email</th>
                                <th className='max-w-200px'>Manage Organisation</th>
                                <th className='max-w-250px'>Manage Applications</th>
                                <th className='max-w-150px'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {organistionUsers.map(organisationUser => (
                                <tr key={organisationUser.id}>
                                    <td className='px-4'>{organisationUser.user.first_name + organisationUser.user.last_name}</td>
                                    <td className='px-4'>{organisationUser.user.email}</td>
                                    <td className='px-4'>{organisationUser.can_manage_organization == '1' ? 'Yes' : 'No'}</td>
                                    <td className='px-4'>{organisationUser.can_manage_application == '1' ? 'Yes' : 'No'}</td>
                                    <td className='px-4'>
                                        <button
                                            data-toggle="tooltip" data-placement="bottom" title="Edit Organisation"
                                            className='btn btn-icon btn-light-dark btn-active-color-success btn-sm me-2'
                                            data-bs-toggle='modal'
                                            data-bs-target='#kt_modal_4'
                                            onClick={() => setSelectedOrganisationUser(organisationUser)}
                                        >
                                            <KTIcon iconName='pencil' className='fs-3' />
                                        </button>
                                        {/* <button
                                            data-toggle="tooltip" data-placement="bottom" title="Delete Organisation User"
                                            className='btn btn-icon btn-light-dark btn-active-color-success btn-sm me-2'
                                            data-bs-toggle='modal'
                                            data-bs-target='#kt_modal_1'
                                            onClick={() => setSelectedOrganisationUser(organisationUser)}
                                        >
                                            <KTIcon iconName='trash' className='fs-3' />
                                        </button> */}
                                        <button
                                            data-toggle="tooltip" data-placement="bottom" title="Edit Organisation"
                                            className='btn btn-icon btn-light-dark btn-active-color-success btn-sm me-2'
                                            data-bs-toggle='modal'
                                            data-bs-target='#kt_modal_3'
                                            onClick={() => setSelectedOrganisationUser(organisationUser)}
                                        >
                                            <KTIcon iconName='eye' className='fs-3' />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Add User */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Add Organisation User</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-dark ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <>
                                {/* ... Edit Form ... */}
                                <Form>
                                    <div className='form-group'>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                Email
                                            </label>
                                            <div className='col-lg-6 fv-row'>
                                                <input
                                                    type='email'
                                                    value={emailvalue}
                                                    className='form-control form-control-lg form-control-solid user-input'
                                                    placeholder='Write Email Address'
                                                    // onChange={(e) => { setEmailValue(e.target.value) }}
                                                />
                                            </div>
                                            <div className='col-lg-2 mt-2'>
                                                <button type='button' className='btn btn-sm btn-dark user-input'
                                                    onClick={() => {
                                                        verifyUserMutation.mutate(
                                                            { email: emailvalue },
                                                        );
                                                        setIsEditable(true); // Enable fields after data fetch.
                                                    }}
                                                >
                                                    Fetch Data
                                                </button>
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                First Name
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid user-input'
                                                    placeholder='Write First Name'
                                                    value={firstNameValue}
                                                    onChange={(e) => setFirstNameValue(e.target.value)}
                                                    readOnly={!isEditable}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                Middle Name
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid user-input'
                                                    placeholder='Write Middle Name'
                                                    value={middleNameValue}
                                                    onChange={(e) => setMiddleNameValue(e.target.value)}
                                                    readOnly={!isEditable}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                Last Name
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid user-input'
                                                    placeholder='Write Last Name'
                                                    value={lastNameValue}
                                                    onChange={(e) => setLastNameValue(e.target.value)}
                                                    readOnly={!isEditable}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                                                Mobile Number
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid user-input'
                                                    placeholder='Write Mobile Number'
                                                    value={mobileValue}
                                                    readOnly={!isEditable}
                                                    onChange={(e) => setMobileValue(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Picture</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='file'
                                                    className='form-control form-control-lg form-control-solid'
                                                    onChange={(e) => HandleImageChange(e)}
                                                    name='picture'
                                                />
                                                <div className='text-danger mt-2'>
                                                    Recommended size 200 x 200 px. jpg, jpeg, or png only
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-6 mx-1'>
                                            <div className='col-lg-4'></div>
                                            <div className='col-lg-8'>
                                                <div className="form-check form-check-custom form-check-solid form-check-sm col-lg-12 mb-6">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={manageOrganisationValue}
                                                        id="flexRadioLg"
                                                        checked={manageOrganisationValue === 1}
                                                        onChange={() => setManageOrganisationValue(manageOrganisationValue === 1 ? 0 : 1)}
                                                    />
                                                    <label className="form-check-label text-dark" htmlFor="flexRadioLg">
                                                        Manage Organisations
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-custom form-check-solid form-check-sm col-lg-12 mb-6">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={manageApplicationValue}
                                                        id="flexRadioLg"
                                                        checked={manageApplicationValue === 1}
                                                        onChange={() => setManageApplicationValue(manageApplicationValue === 1 ? 0 : 1)}
                                                    />
                                                    <label className="form-check-label text-dark" htmlFor="flexRadioLg">
                                                        Manage Applications
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        </div>

                        <div className='modal-footer p-0'>
                            <div className='card-footer d-flex justify-content-end py-6 px-4 '>
                                <button
                                    data-toggle="tooltip" data-placement="bottom" title="Add User"
                                    type='button'
                                    className={`btn btn-dark ${!firstNameValue || !emailvalue || !lastNameValue ? 'disabled' : ''}`}
                                    data-bs-dismiss='modal'
                                    onClick={() => {
                                        handleAddOrganisationUser.mutate({
                                            email: emailvalue,
                                            first_name: firstNameValue,
                                            middle_name: middleNameValue,
                                            last_name: lastNameValue,
                                            mobile_no: mobileValue,
                                            profile_picture: userPicture,
                                            organization_id: organisation?.id,
                                            can_manage_organization: manageOrganisationValue,
                                            can_manage_application: manageApplicationValue,
                                        })
                                    }}
                                >
                                    Add User

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Delete modal */}
            {/* <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Delete Organisation User Confirmation</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedOganistionUser ? (
                                <>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Full Name: </span> {selectedOganistionUser.user.first_name + " " + selectedOganistionUser.user.last_name}
                                    </p>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Emails: </span>{' '}
                                        {selectedOganistionUser.user.email}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className='modal-footer'>
                            <button data-toggle="tooltip" data-placement="bottom" title="close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Organisation"
                                type='button'
                                onClick={() => {
                                    if (selectedOganistionUser && selectedOganistionUser.user_id && selectedOganistionUser.organization_id) {
                                        handleDeleteOrganisationUser.mutate({
                                            user_id: selectedOganistionUser.user_id,
                                            organization_id: selectedOganistionUser.organization_id,
                                        })
                                    }
                                }}
                                className='btn btn-danger'
                                data-bs-dismiss='modal'
                            >
                                Delete Organisation
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>View Organisation User</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedOganistionUser ? (
                                <form>
                                    <div className='form-group row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Email</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <label className='form-control form-control-lg form-control-solid user-input'>
                                                {selectedOganistionUser.user.email}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='form-group row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>first Name</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <label className='form-control form-control-lg form-control-solid user-input'>
                                                {selectedOganistionUser.user.first_name}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='form-group row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span>Middle Name</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <label className='form-control form-control-lg form-control-solid user-input'>
                                                {selectedOganistionUser.user.middle_name}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='form-group row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Last Name</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <label className='form-control form-control-lg form-control-solid user-input'>
                                                {selectedOganistionUser.user.last_name}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='form-group row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Mobile No</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <label className='form-control form-control-lg form-control-solid user-input'>
                                                {selectedOganistionUser.user.mobile_no}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    Existing Picture
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <img src={selectedOganistionUser ? selectedOganistionUser.user.profile_picture : ''} alt="No Image Found" />
                                                </div>
                                            </div>
                                    <div className='row mb-6 mx-1'>
                                        <div className='col-lg-4'></div>
                                        <div className='col-lg-8'>
                                            <div className="form-check form-check-custom form-check-solid form-check-sm col-lg-12 mb-6">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="flexRadioLg"
                                                    checked={selectedOganistionUser.can_manage_organization == '1'}
                                                />
                                                <label className="form-check-label text-dark" htmlFor="flexRadioLg">
                                                    Manage Organisations
                                                </label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid form-check-sm col-lg-12 mb-6">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="flexRadioLg"
                                                    checked={selectedOganistionUser.can_manage_application == '1'}
                                                />
                                                <label className="form-check-label text-dark" htmlFor="flexRadioLg">
                                                    Manage Applications
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit User */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_4'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Organisation User</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-dark ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedOganistionUser ? (
                                <>
                                    {/* ... Edit Form ... */}
                                    <Form>
                                        <div className='form-group'>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                    Email
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                        type='email'
                                                        className='form-control form-control-lg form-control-solid user-input'
                                                        placeholder='Write Email Address'
                                                        value={selectedOganistionUser ? selectedOganistionUser.user.email : ''}
                                                        // onChange={(e) => {
                                                        //     setSelectedOrganisationUser({
                                                        //         ...selectedOganistionUser,
                                                        //         user: {
                                                        //             ...selectedOganistionUser.user,
                                                        //             email: e.target.value,
                                                        //         },
                                                        //     });
                                                        // }}
                                                    />
                                                </div>
                                                {/* <div className='col-lg-2 mt-2'>
                                                    <button type='button' className='btn btn-sm btn-dark user-input'
                                                        onClick={() => {
                                                            verifyUserMutation.mutate({
                                                                email: emailvalue
                                                            })
                                                        }}
                                                    >
                                                        Fetch Data
                                                    </button>
                                                </div> */}
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                    First Name
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid user-input'
                                                        placeholder='Write First Name'
                                                        value={selectedOganistionUser ? selectedOganistionUser.user.first_name : ''}
                                                        onChange={(e) => {
                                                            setSelectedOrganisationUser({
                                                                ...selectedOganistionUser,
                                                                user: {
                                                                    ...selectedOganistionUser.user,
                                                                    first_name: e.target.value,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    Middle Name
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid user-input'
                                                        placeholder='Write Middle Name'
                                                        value={selectedOganistionUser ? selectedOganistionUser.user.middle_name : ''}
                                                        onChange={(e) => {
                                                            setSelectedOrganisationUser({
                                                                ...selectedOganistionUser,
                                                                user: {
                                                                    ...selectedOganistionUser.user,
                                                                    middle_name: e.target.value,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                    Last Name
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid user-input'
                                                        placeholder='Write Last Name'
                                                        value={selectedOganistionUser ? selectedOganistionUser.user.last_name : ''}
                                                        onChange={(e) => {
                                                            setSelectedOrganisationUser({
                                                                ...selectedOganistionUser,
                                                                user: {
                                                                    ...selectedOganistionUser.user,
                                                                    last_name: e.target.value,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                                                    Mobile Number
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                        type='number'
                                                        className='form-control form-control-lg form-control-solid user-input'
                                                        placeholder='Write Mobile Number'
                                                        value={selectedOganistionUser ? selectedOganistionUser.user.mobile_no : ''}
                                                        onChange={(e) => {
                                                            setSelectedOrganisationUser({
                                                                ...selectedOganistionUser,
                                                                user: {
                                                                    ...selectedOganistionUser.user,
                                                                    mobile_no: e.target.value,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Picture</label>
                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                        type='file'
                                                        className='form-control form-control-lg form-control-solid'
                                                        onChange={(e) => HandleImageChange(e)}
                                                        name='picture'
                                                    />
                                                    <div className='text-danger mt-2'>
                                                        Recommended size 200 x 200 px. jpg, jpeg, or png only
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    Existing Picture
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <img src={selectedOganistionUser ? selectedOganistionUser.user.profile_picture : ''} alt="No Image Found" />
                                                </div>
                                            </div>
                                            <div className='row mb-6 mx-1'>
                                                <div className='col-lg-4'></div>
                                                <div className='col-lg-8'>
                                                    <div className="form-check form-check-custom form-check-solid form-check-sm col-lg-12 mb-6">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="manageOrganization"
                                                            checked={selectedOganistionUser?.can_manage_organization == '1'} // Check for value '1'
                                                            onChange={() => {
                                                                setSelectedOrganisationUser({
                                                                    ...selectedOganistionUser,
                                                                    can_manage_organization:
                                                                        selectedOganistionUser.can_manage_organization === '1' ? '0' : '1', // Toggle between '1' and '0'
                                                                });
                                                            }}
                                                        />
                                                        <label className="form-check-label text-dark" htmlFor="manageOrganization">
                                                            Manage Organisations
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-custom form-check-solid form-check-sm col-lg-12 mb-6">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="manageApplication"
                                                            checked={selectedOganistionUser?.can_manage_application == '1'} // Check for value '1'
                                                            onChange={() => {
                                                                setSelectedOrganisationUser({
                                                                    ...selectedOganistionUser,
                                                                    can_manage_application:
                                                                        selectedOganistionUser.can_manage_application === '1' ? '0' : '1', // Toggle between '1' and '0'
                                                                });
                                                            }}
                                                        />
                                                        <label className="form-check-label text-dark" htmlFor="manageApplication">
                                                            Manage Applications
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            ) : null}
                        </div>

                        <div className='modal-footer p-0'>
                            <div className='card-footer d-flex justify-content-end py-6 px-4 '>
                                <button
                                    data-toggle="tooltip" data-placement="bottom" title="Update User"
                                    type='button'
                                    className={`btn btn-dark ${!selectedOganistionUser?.user.email || !selectedOganistionUser?.user.first_name || !selectedOganistionUser?.user.last_name || !selectedOganistionUser?.user.mobile_no ? 'disabled' : ''}`}
                                    data-bs-dismiss='modal'
                                    onClick={() => {
                                        handleEditOrganisationUser.mutate({
                                            user_id: selectedOganistionUser?.user_id,
                                            // email: selectedOganistionUser?.user.email,
                                            first_name: selectedOganistionUser?.user.first_name,
                                            middle_name: selectedOganistionUser?.user.middle_name,
                                            last_name: selectedOganistionUser?.user.last_name,
                                            mobile_no: selectedOganistionUser?.user.mobile_no,
                                            organization_id: selectedOganistionUser?.organization_id,
                                            profile_picture: userPicture,
                                            can_manage_organization: selectedOganistionUser?.can_manage_organization,
                                            can_manage_application: selectedOganistionUser?.can_manage_application,
                                        })
                                    }}
                                >
                                    Update User

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrganisationUsers;
