import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { resendEmail } from '../core/_requests'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { clippingParents } from '@popperjs/core'

const verifyEmailSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
})

const initialValues = {
    email: '',
}

function VerifyEmail() {
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues,
        validationSchema: verifyEmailSchema,
        onSubmit: (values, { resetForm }) => {
            setLoading(true)

            resendEmail(values.email)
                .then((response) => {
                    // Explicitly check the response structure
                    if (response.data?.status) {
                        // Success response
                        toast.success(response.data.message || 'Verification email sent successfully!', {
                            theme: 'colored',
                            position: 'top-right',
                            autoClose: 5000,
                        })
                        resetForm() // Clear the form
                    } else {
                        // Handle unexpected response with a toast
                        toast.success(response.message || 'Unexpected response from the server.', {
                            theme: 'colored',
                            position: 'top-right',
                            autoClose: 5000,
                        })
                    }
                })
                .catch((error) => {
                    // Handle errors (e.g., network issues or non-200 status)
                    const errorMessage =
                        error.response?.data?.message || 'An error occurred. Please try again.'
                    toast.error(errorMessage, {
                        theme: 'colored',
                        position: 'top-right',
                        autoClose: 5000,
                    })
                })
                .finally(() => {
                    setLoading(false) // Ensure loading state is reset
                })
        },
    })

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                <h1 className='text-dark fw-bolder mb-3'>Didn't get Verified?</h1>
                <div className='text-gray-500 fw-semibold fs-6'>
                    Enter your email address to get a new verification email.
                </div>
            </div>

            {/* Email Input */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                <input
                    type='email'
                    placeholder='Email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>

            {/* Submit Button */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                    <span className='indicator-label'>
                        {loading ? 'Sending...' : 'Submit'}
                    </span>
                    {loading && (
                        <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light'
                    >
                        Cancel
                    </button>
                </Link>
            </div>
        </form>
    )
}

export default VerifyEmail
