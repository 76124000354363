import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { resetPassword } from '../core/_requests';
import { Link, useNavigate } from 'react-router-dom';

function ResetPassword() {
    const [userEmail, setUserEmail] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState({ 'password': '', 'confirm_password': '' })
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        // Get the query string part of the URL
        const queryString = window.location.search;
        // Get the URL path
        const paramsString = queryString.slice(1);
        // Split the parameters string by '&' to separate individual key-value pairs
        const paramsList = paramsString.split('&');
        // Initialize variables to store order ID and token
        var userEmail: string | null = null;
        var token: string | null = null;
        // Iterate over key-value pairs
        for (const param of paramsList) {
            const [key, value] = param.split('=');
            if (key === 'user_email') {
                userEmail = value;
                setUserEmail(userEmail)
            }
             if (key === 'token') {
                token = value;
                const encodedToken = token;
                const decodedToken = decodeURIComponent(encodedToken);
                setToken(decodedToken)
            }
        }
    }, []);
    const navigate=useNavigate();
    async function  ResetPasswordStatus(token, userEmail, password, password_confirmation) {
        try {
            // Make POST request to API endpoint using the function from the API file
            const response = await resetPassword(token, userEmail, password, password_confirmation);
            toast.success("Password is updated successfully", {
                position: 'top-right',
                autoClose: 3000,
            });
            navigate("/auth/login");
        } catch (error:any) {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                  position: 'top-right',
                  autoClose: 2000,
                })
              }
        }
    }
    const handleChange = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (password.password === password.confirm_password) {
            ResetPasswordStatus(token, userEmail, password.password, password.confirm_password);
        } else {
            toast.error("Password not matched.", {
                theme: "colored",
            });
            return;
        }
    }
    return (
        <div className='p-5'>
            <form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-center'>
                    <div className="col-md-12">
                        {/* <div className="border border-3 border-success"></div> */}
                        <h1 className='text-center pt-5 pb-5'>Reset Password</h1>
                        <div className="mx-5">
                            <div className="mb-4">
                                <label className="col-form-label fw-bold fs-6">Your Email</label>
                                <input
                                    type="text"
                                    className="form-control bg-transparent"
                                    placeholder={userEmail}
                                    value={userEmail}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <label className="form-label fw-bold fs-6 required">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    className="form-control bg-transparent"
                                    placeholder="Enter Password"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="col-form-label fw-bold fs-6 required">Confirm Password</label>
                                <input
                                    type="password"
                                    name="confirm_password"
                                    className="form-control bg-transparent"
                                    placeholder="Enter Confirm Password"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        { }
                    </div>
                </div>
                 {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' disabled={loading} className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {!loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            className='btn btn-light'
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
            </form>
        </div>
    );
};
export default ResetPassword