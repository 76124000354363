import React, { useEffect } from 'react';

const StoreToken: React.FC = () => {
    useEffect(() => {
        window.addEventListener('message', (event) => {
            const { token, expiration } = event.data;
            localStorage.setItem('kt-auth-react-v', JSON.stringify(token));
            localStorage.setItem('kt-auth-react-v-expiration', expiration);
            const source = event.source as Window | null;
            if (source) {
                source.postMessage('Token stored successfully', event.origin);
            } else {
                console.warn('Internal server error!');
            }
        });
    }, []);

    return (
        <div>
            <h1>Store Token</h1>
        </div>
    );
};

export default StoreToken;
