import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'

export function Logout() {
  const {logout} = useAuth()
  useEffect(() => {
    localStorage.removeItem('kt-auth-react-v');
    localStorage.removeItem('kt-auth-react-v-expiration');
    localStorage.removeItem('kt_theme_mode_value');
    window.location.href = '/auth/login';
  }, [logout])
  return (
    <>
    </>
  )
}
