import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { OrganisationListInterface, countryInterface } from '../../../modules/accounts/components/settings/SettingsModel';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { get_all_countries } from '../../../modules/auth/core/_requests';
import { toast } from 'react-toastify';
import { update_organisation } from '../api/OrganisationsApi';
import { Form } from 'react-bootstrap';

function EditOrganisation() {
    const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationListInterface | null>();
    const [countriesList, setCountriesList] = useState<countryInterface[]>([]);
    // const [applicationList, setApplicationList] = useState<applicationsInterface[]>([]);
    const [whiteLogo, setWhiteLogo] = useState<File | null>(null);
    const [colorLogo, setColorLogo] = useState<File | null>(null);
    const [organisationIcon, setOrganisationIcon] = useState<File | null>(null);
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const ref = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const organisation = (location.state as { organisation: OrganisationListInterface }).organisation;
    // const { data: applicationData } = useQuery('applications', get_all_applications, { refetchOnWindowFocus: false });
    // useEffect(() => {
    //     if (applicationData) {
    //         setApplicationList(applicationData.data as applicationsInterface[]);
    //     }
    // }, [applicationData]);
    const { data: countryData } = useQuery('countries', get_all_countries, { refetchOnWindowFocus: false });
    useEffect(() => {
        if (countryData) {
            setCountriesList(countryData.data as countryInterface[]);
        }
    }, [countryData]);
    useEffect(() => {
        setSelectedOrganisation(organisation);
    }, [organisation]);
    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newName = e.target.value;
            if (!newName) {
                setNameError('Organisation Name is required.');
            } else {
                setNameError('');
            };
            setSelectedOrganisation({
                ...selectedOrganisation,
                name: newName,
            });
        };
    };
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newEmail = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                email: newEmail,
            });
        };
    };
    const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newPhoneNumber = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                phone: newPhoneNumber,
            });
        }
    };
    const handleMobileNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newMobile = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                mobile: newMobile,
            });
        };
    };
    const handleAddress1Change = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newAddress1 = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                address_1: newAddress1,
            });
        };
    };
    const handleAddress2Change = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newAddress2 = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                address_2: newAddress2,
            });
        };
    };
    const handleTownChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newTown = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                town: newTown,
            });
        };
    };
    const handleCityChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newCity = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                city: newCity,
            });
        };
    };
    const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (selectedOrganisation) {
            const newCountry = Number(e.target.value);
            setSelectedOrganisation({
                ...selectedOrganisation,
                country_id: newCountry,
            });
        };
    };
    const handlePostcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newPostcode = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                post_code: newPostcode,
            });
        };
    };
    const handleColorLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newColorLogo = e.target.files?.[0] || null;
            setSelectedOrganisation({
                ...selectedOrganisation,
                color_logo: newColorLogo ? URL.createObjectURL(newColorLogo) : selectedOrganisation.color_logo,
            });
            setColorLogo(newColorLogo); // Save file for upload
        }
    };

    // Function to handle white logo change
    const handleWhiteLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newWhiteLogo = e.target.files?.[0] || null;
            setSelectedOrganisation({
                ...selectedOrganisation,
                white_logo: newWhiteLogo ? URL.createObjectURL(newWhiteLogo) : selectedOrganisation.white_logo,
            });
            setWhiteLogo(newWhiteLogo); // Save file for upload
        }
    };

    // Function to handle organisation icon change
    const handleIconChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newIcon = e.target.files?.[0] || null;
            setSelectedOrganisation({
                ...selectedOrganisation,
                icon: newIcon ? URL.createObjectURL(newIcon) : undefined,
            });
            setOrganisationIcon(newIcon); // Save file for upload
        }
    };
    const handleStatusChange = (e: any) => {
        if (selectedOrganisation) {
            const newStatus = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                status: newStatus,
            });
        }
    };
    // commenting for future use 
    // const handleSelectApplication = (applicationId: number) => {
    //     if (!selectedOrganisation) return; // Guard clause for null state

    //     const isApplicationSelected = selectedOrganisation.applications.some(item => item.id === applicationId);

    //     let updatedApplications: OrganisationListInterface['applications'];

    //     if (isApplicationSelected) {
    //         // If the application ID exists, remove it
    //         updatedApplications = selectedOrganisation.applications.filter(item => item.id !== applicationId);
    //     } else {
    //         const newApplication = {
    //             id: applicationId,
    //             name: '',
    //             description: '',
    //             website_url: '',
    //             api_url: '',
    //             application_organization_id: 0,
    //         };

    //         updatedApplications = [...selectedOrganisation.applications, newApplication];
    //     }
    //     setSelectedOrganisation({
    //         ...selectedOrganisation,
    //         applications: updatedApplications,
    //     });
    // };
    const handleUpdateOrganisation = () => {
        try {
            setLoading(true);
            if (!selectedOrganisation) {
                throw new Error('No Organisation Selected for Update');
            }
    
            const {
                id,
                name,
                email,
                phone,
                mobile,
                address_1,
                address_2,
                town,
                city,
                country_id,
                post_code,
                status,
            } = selectedOrganisation;
    
            const formData = new FormData();
            formData.append('name', name || '');
            formData.append('email', email || '');
            formData.append('phone', phone || '');
            formData.append('mobile', mobile || '');
            formData.append('address_1', address_1 || '');
            formData.append('address_2', address_2 || '');
            formData.append('town', town || '');
            formData.append('city', city || '');
            formData.append('country_id', country_id ? country_id.toString() : '');
            formData.append('post_code', post_code || '');
            formData.append('status', status.toString());
    
            // Append files if they exist
            if (colorLogo) {
                formData.append('color_logo', colorLogo, colorLogo.name);
            }
            if (whiteLogo) {
                formData.append('white_logo', whiteLogo, whiteLogo.name);
            }
            if (organisationIcon) {
                formData.append('icon', organisationIcon, organisationIcon.name);
            }
    
            // Append applications array
            // if (applications && applications.length > 0) {
            //     applications.forEach((application, index) => {
            //         formData.append(`applications[${index}][id]`, application.application_organization_id.toString());
            //         formData.append(`applications[${index}][application_id]`, application.id.toString());
            //     });
            // }
            formData.forEach((value, key) => {
                console.log(key, value);
            });
            updateOrganisationMutation.mutate({
                id,
                formData,
            });
        } catch (error: any) {
            toast.error(error.message || 'An error occurred.', {
                position: 'top-right',
                autoClose: 3000,
            });
            setLoading(false);
        }
    };
    

    const updateOrganisationMutation = useMutation((Data: any) => update_organisation(Data.id, Data.formData), {
        onSuccess: (response) => {
            //   refetch();
            setLoading(false);
            navigate('/organisations/organisations-list');
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
            ref.current && (ref.current.value = '')
        },
        onError: (error: any) => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
                setLoading(false);
            } else if (error.request) {
                // The request was made but no response was received
                toast.error('No response received from the server.', {
                    position: "top-right",
                    autoClose: 3000,
                });
                setLoading(false);
            } else {
                toast.error('An unexpected error occurred.', {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
            setLoading(false);
        },
    });
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Edit Organisation</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List of Organisation"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/organisations')}
                    >
                        List of Organisations
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {selectedOrganisation ? (
                        <>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                    <span>Organisation Name</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Organisation Name'
                                        value={selectedOrganisation ? selectedOrganisation.name : ''}
                                        onChange={(e) => handleNameChange(e)}
                                    />
                                    {nameError && (
                                        <div className='text-danger'>{nameError}</div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span>Organisation Email</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Organisation Email'
                                        value={selectedOrganisation ? selectedOrganisation.email : ''}
                                        onChange={(e) => handleEmailChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Phone Number</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Phone Number'
                                        value={selectedOrganisation ? selectedOrganisation.phone : ''}
                                        onChange={(e) => handlePhoneChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    Mobile Number
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Mobile number'
                                        value={selectedOrganisation ? selectedOrganisation.mobile : ''}
                                        onChange={(e) => handleMobileNumberChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6 '>Address Line 1</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Address Line 1'
                                        value={selectedOrganisation ? selectedOrganisation.address_1 : ''}
                                        onChange={(e) => handleAddress1Change(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Address Line 2</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Address Line 2'
                                        value={selectedOrganisation ? selectedOrganisation.address_2 : ''}
                                        onChange={(e) => handleAddress2Change(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Town</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Town'
                                        value={selectedOrganisation ? selectedOrganisation.town : ''}
                                        onChange={(e) => handleTownChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span>Country</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <select
                                        className='form-select form-select-solid form-select-lg fw-bold'
                                        value={selectedOrganisation ? selectedOrganisation.country_id : ''}
                                        onChange={(e) => handleCountryChange(e)}
                                    >
                                        <option value=''>Select a Country...</option>
                                        {Array.isArray(countriesList) && countriesList.length > 0 ? (
                                            countriesList.map((country) => (
                                                <option key={country.id} value={country.id}>
                                                    {country.country_name}
                                                </option>
                                            ))
                                        ) : (
                                            <option value='' disabled>No Country available</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>City</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='City'
                                        value={selectedOrganisation ? selectedOrganisation.city : ''}
                                        onChange={(e) => handleCityChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Postcode</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Postcode'
                                        value={selectedOrganisation ? selectedOrganisation.post_code : ''}
                                        onChange={(e) => handlePostcodeChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Color Logo</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='file'
                                        className='form-control form-control-lg form-control-solid'
                                        onChange={(e) => handleColorLogoChange(e)}
                                        name='Color Logo'
                                    />
                                    <div className='text-danger mt-2'>
                                        Recommended size 200 x 200 px. jpg, jpeg, or png only
                                    </div>
                                    {selectedOrganisation.color_logo && (
                                        <div className='mt-2'>
                                            <img
                                                src={selectedOrganisation.color_logo}
                                                alt='Color Logo'
                                                className='symbol'
                                                width={200}
                                                height={200}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>White Logo</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='file'
                                        className='form-control form-control-lg form-control-solid'
                                        onChange={(e) => handleWhiteLogoChange(e)}
                                        name='picture'
                                    />
                                    <div className='text-danger mt-2'>
                                        Recommended size 200 x 200 px. jpg, jpeg, or png only
                                    </div>
                                    {selectedOrganisation.white_logo && (
                                        <div className='mt-2'>
                                            <img
                                                src={selectedOrganisation.white_logo}
                                                alt='White Logo'
                                                className='symbol'
                                                width={200}
                                                height={200}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Icon</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='file'
                                        className='form-control form-control-lg form-control-solid'
                                        onChange={(e) => handleIconChange(e)}
                                        name='Icon'
                                    />
                                    {selectedOrganisation.icon && (
                                        <div className='mt-2'>
                                            <img
                                                src={selectedOrganisation.icon}
                                                alt='Icon'
                                                className='symbol'
                                                width={200}
                                                height={200}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span>Status</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <Form.Select
                                        className='form-select form-select-solid form-select-lg fw-bold'
                                        value={selectedOrganisation ? selectedOrganisation.status : ''}
                                        onChange={(e) => handleStatusChange(e)}
                                    >
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                    </Form.Select>
                                </div>
                            </div> */}
                            {/* commenting for future use  */}
                            {/* <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Applications</span>
                                </label>
                                <div className='col-lg-8 fv-row row'>
                                    {applicationList.map((application, index) => (
                                        <Card
                                            key={application.id}
                                            className={`col-lg-5 col-md-6 add-application-card mx-5 mt-2 ${selectedOrganisation?.applications.some(app => app.id === application.id) ? 'selected-application-card' : ''}`}
                                            onClick={() => handleSelectApplication(application.id)}
                                            style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})` }}
                                        >
                                            <Card.Body>
                                                <Card.Title className='text-light'>{application.name}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-secondary">{application.description}</Card.Subtitle>
                                                <Card.Link href={application.website_url}>Take a Look</Card.Link>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </div>

                            </div> */}
                        </>
                    ) : null}
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {nameError || emailError ? (
                        <button type='button' data-toggle="tooltip" data-placement="bottom" title="Update Organisation" className='btn btn-primary disabled'>
                            Update Organisation
                        </button>
                    ) : (
                        <button
                            data-toggle="tooltip" data-placement="bottom" title="Update Organisation"
                            type='button'
                            className='btn btn-primary'
                            onClick={handleUpdateOrganisation}
                            disabled={loading}
                        >
                            {!loading && 'Update Organisation'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EditOrganisation