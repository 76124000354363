/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, ChangeEvent } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { get_all_countries, register } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import { applicationsInterface, countryInterface } from '../../accounts/components/settings/SettingsModel'
import { get_all_applications } from '../../../pages/applications/api/applicationsApi'
import { KTSVG } from '../../../../_metronic/helpers'
const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  organisation_name: '',
  organisation_email: '',
  telephone: '',
  mobile_no: '',
  address_line_1: '',
  address_line_2: '',
  town: '',
  city: '',
  country: '',
  postcode: '',
  // applications: [],
  status: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  organisation_name: Yup.string().required('Organisation Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Passwords don't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})
interface registerInterface {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  changepassword: string;
  organisation_name: string;
  organisation_email: string;
  telephone: string;
  mobile_no: string;
  address_line_1: string;
  address_line_2: string;
  town: string;
  city: string;
  country: string;
  postcode: string;
  // applications: number[];
  status: string;
  acceptTerms: boolean;
}
export function Registration() {
  const [loading, setLoading] = useState(false)
  const [applicationList, setApplicationList] = useState<applicationsInterface[]>([])
  const [countriesList, setCountriesList] = useState<countryInterface[]>([])
  const [whiteLogo, setWhiteLogo] = useState<File | null>(null);
  const [colorLogo, setColorLogo] = useState<File | null>(null);
  const [organisationIcon, setOrganisationIcon] = useState<File | null>(null);
  const [step, setStep] = useState(1)
  const navigate = useNavigate();
  const { data: applicationData } = useQuery('applications', get_all_applications, {
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    if (applicationData) {
      setApplicationList(applicationData.data as applicationsInterface[])
    }
  }, [applicationData])
  const { data: countryData } = useQuery('countries', get_all_countries, {
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    if (countryData) {
      setCountriesList(countryData.data as countryInterface[])
    }
  }, [countryData])
  const formik = useFormik<registerInterface>({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      console.log("values",values);
      try {
        const formData = new FormData();
        formData.append('first_name', values.firstname);
        formData.append('last_name', values.lastname);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('password_confirmation', values.changepassword);
        formData.append('name', values.organisation_name);
        formData.append('status', values.status || '1');
        formData.append('address_1', values.address_line_1);
        formData.append('address_2', values.address_line_2);
        formData.append('town', values.town);
        formData.append('city', values.city);
        formData.append('country_id',values.country);
        formData.append('post_code', values.postcode);
        formData.append('phone', values.telephone);
        formData.append('mobile', values.mobile_no);
        formData.append('org_email', values.organisation_email);
        // values.applications.forEach((application) => {
        //   formData.append('applications[]', application.toString());
        // });
        if (colorLogo) formData.append('color_logo', colorLogo);
        if (whiteLogo) formData.append('white_logo', whiteLogo);
        if (organisationIcon) formData.append('icon', organisationIcon);

        const response = await register(formData)

        setLoading(false);

        toast.success(response.message, {
          theme: 'colored',
        });
        navigate('/auth')
      } catch (error) {

        let errorMsg = 'An unknown error occurred';

        // Safely access error properties
        if (axios.isAxiosError(error) && error.response) {
          const errorsObj = error.response.data.errors;

          // Handle error response
          if (errorsObj) {
            errorMsg = Object.values(errorsObj).join(', ');
          }
        }

        setStatus(errorMsg);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  const HandleColorLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setColorLogo(e.target.files[0]);
    };
  };
  const HandleWhiteLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setWhiteLogo(e.target.files[0]);
    };
  };
  const HandleIconChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setOrganisationIcon(e.target.files[0]);
    };
  };
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  // const handleSelectApplication = (applicationId) => {
  //   let updatedApplications;

  //   if (formik.values.applications.includes(applicationId)) {
  //     updatedApplications = formik.values.applications.filter(item => item !== applicationId);
  //   } else {
  //     updatedApplications = [...formik.values.applications, applicationId];
  //   }
  //   formik.setFieldValue('applications', updatedApplications);
  // };
  const handleNextButton = () => {
    setStep(2);
    const prevButton = document.querySelector('.prev-button')
    const nextButton = document.querySelector('.next-button')
    const submitButton = document.querySelector('.submit-button')
    const userDiv = document.querySelector('.user-div')
    const orgDiv = document.querySelector('.org-div')

    prevButton?.classList.remove('disabled')
    orgDiv?.classList.remove('d-none')
    submitButton?.classList.remove('d-none')

    nextButton?.classList.add('disabled', 'd-none')
    userDiv?.classList.add('d-none')
  }
  const handlePrevButton = () => {
    setStep(1)
    const prevButton = document.querySelector('.prev-button')
    const nextButton = document.querySelector('.next-button')
    const submitButton = document.querySelector('.submit-button')
    const userDiv = document.querySelector('.user-div')
    const orgDiv = document.querySelector('.org-div')
    // adding classes
    prevButton?.classList.add('disabled')
    orgDiv?.classList.add('d-none')
    submitButton?.classList.add('d-none')
    //
    nextButton?.classList.remove('disabled', 'd-none')
    userDiv?.classList.remove('d-none')
  }
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework p-20'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='d-flex justify-content-between align-items-center mb-11 mx-15 row'>
        {/* Left-aligned button */}
        <div className='col-lg-5'>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary'
            >
              {`<`} Back to Log In
            </button>
          </Link>
        </div>

        {/* Centered heading */}
        <div className='flex-grow-1 col-lg-6'>
          <h1 className='text-dark fw-bolder'>Register User</h1>
        </div>
      </div>

      {/* end::Heading */}
      <div
        className='d-flex align-items-center mb-3 mx-20'

      >
        <div className={`flex-grow-1  ${step >= 1 ? 'bg-primary' : 'bg-secondary'} rounded h-5px me-2`}></div>
        <div className={`flex-grow-1  ${step == 2 ? 'bg-primary' : 'bg-secondary'} rounded h-5px me-2`}></div>
      </div>
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      <div className='border border-primary rounded p-7 mx-15 user-div row'>
        <div className='text-left mb-7'>
          <h1 className='text-primary fw-bolder mb-3'>Personal Details</h1>
        </div>
        {/* First Name */}
        <div className='fv-row mb-5 col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6 required'>First name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.firstname && formik.errors.firstname },
              { 'is-valid': formik.touched.firstname && !formik.errors.firstname }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        {/* Last Name */}
        <div className='fv-row mb-5 col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Last name</label>
          <input
            placeholder='Last name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.lastname && formik.errors.lastname },
              { 'is-valid': formik.touched.lastname && !formik.errors.lastname }
            )}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
        </div>
        {/* Email */}
        <div className='fv-row mb-5 col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              { 'is-valid': formik.touched.email && !formik.errors.email }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* Password */}
        <div className='fv-row mb-5 col-lg-6' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Password</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.password && formik.errors.password },
                  { 'is-valid': formik.touched.password && !formik.errors.password }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
          </div>
          <div className='text-muted'>
            Use 8 or more characters with a mix of letters, numbers & symbols.
          </div>
        </div>
        {/* Confirm Password */}
        <div className='fv-row mb-2 col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Confirm Password</label>
          <input
            type='password'
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.changepassword && formik.errors.changepassword },
              { 'is-valid': formik.touched.changepassword && !formik.errors.changepassword }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='dark-primary-border rounded p-7 mx-15 org-div d-none row'>
        <div className='text-left mb-7'>
          <h1 className='dark-primary-text fw-bolder mb-3'>Organisation Details</h1>
        </div>
        {/* Organisation Name */}
        <div className='fv-row mb-5 col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Organisation Name</label>
          <input
            placeholder='Organisation Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('organisation_name')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.organisation_name && formik.errors.organisation_name },
              { 'is-valid': formik.touched.organisation_name && !formik.errors.organisation_name }
            )}
          />
          {formik.touched.organisation_name && formik.errors.organisation_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.organisation_name}</span>
              </div>
            </div>
          )}
        </div>
        {/* Organisation email */}
        <div className='fv-row mb-5 col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('organisation_email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.organisation_email && formik.errors.organisation_email },
              { 'is-valid': formik.touched.organisation_email && !formik.errors.organisation_email }
            )}
          />
        </div>

        {/* Telephone */}
        <div className='fv-row mb-5 col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label>
          <input
            placeholder='Phone Number'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('telephone')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.telephone && formik.errors.telephone },
              { 'is-valid': formik.touched.telephone && !formik.errors.telephone }
            )}
          />
        </div>
        {/* Mobile Number */}
        <div className='fv-row mb-5 col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Mobile Number</label>
          <input
            placeholder='Mobile Number'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('mobile_no')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.mobile_no && formik.errors.mobile_no },
              { 'is-valid': formik.touched.mobile_no && !formik.errors.mobile_no }
            )}
          />
        </div>
        {/* Address Line 1 */}
        <div className='fv-row mb-5 col-lg-12'>
          <label className='form-label fw-bolder text-dark fs-6'>Address Line 1</label>
          <textarea
            placeholder='Address Line 1'
            autoComplete='off'
            {...formik.getFieldProps('address_line_1')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.address_line_1 && formik.errors.address_line_1 },
              { 'is-valid': formik.touched.address_line_1 && !formik.errors.address_line_1 }
            )}
          />
        </div>
        <div className='fv-row mb-5 col-lg-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Address Line 2</label>
          <textarea
            placeholder='Address Line 2'
            autoComplete='off'
            {...formik.getFieldProps('address_line_2')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.address_line_2 && formik.errors.address_line_2 },
              { 'is-valid': formik.touched.address_line_2 && !formik.errors.address_line_2 }
            )}
          />
        </div>
        {/* Town */}
        <div className='fv-row mb-5 col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>Town</label>
          <input
            placeholder='Town'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('town')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.town && formik.errors.town },
              { 'is-valid': formik.touched.town && !formik.errors.town }
            )}
          />
        </div>
        {/* City */}
        <div className='fv-row mb-5 col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>City</label>
          <input
            placeholder='City'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('city')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.city && formik.errors.city },
              { 'is-valid': formik.touched.city && !formik.errors.city }
            )}
          />
        </div>

        {/* country */}
        <div className='fv-row mb-5 col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>Country</label>
          <select
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.country && formik.errors.country },
              { 'is-valid': formik.touched.country && !formik.errors.country }
            )}
            {...formik.getFieldProps('country')}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          >
            <option value=''>Select a Country...</option>
            {Array.isArray(countriesList) && countriesList.length > 0 ? (
              countriesList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.country_name}
                </option>
              ))
            ) : (
              <option value='' disabled>No Country available</option>
            )}
          </select>
        </div>

        {/* Postcode */}
        <div className='fv-row mb-5 col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>Postcode</label>
          <input
            placeholder='Postcode'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('postcode')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.postcode && formik.errors.postcode },
              { 'is-valid': formik.touched.postcode && !formik.errors.postcode }
            )}
          />
        </div>
        <div className='fv-row mb-5 col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>Color Logo</label>
            <input
              type='file'
              className={clsx('form-control bg-transparent')}
              onChange={(e) => HandleColorLogoChange(e)}
              name='picture'
            />
            <div className='text-danger mt-2'>
              Recommended size 200 x 200 px. jpg, jpeg, or png only
            </div>
        </div>
        <div className='fv-row mb-5 col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>White Logo</label>
            <input
              type='file'
              onChange={(e) => HandleWhiteLogoChange(e)}
              name='picture'
              className={clsx('form-control bg-transparent')}
            />
            <div className='text-danger mt-2'>
              Recommended size 200 x 200 px. jpg, jpeg, or png only
            </div>
        </div>
        <div className='fv-row mb-5 col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>Icon</label>
            <input
              type='file'
              onChange={(e) => HandleIconChange(e)}
              name='picture'
              className={clsx('form-control bg-transparent')}
            />
        </div>
        {/* <div className='fv-row mb-5 row '>
          <label className='form-label fw-bolder text-dark fs-6 '>Applications</label>
          {applicationList.map((application) => (
            <Card className={`col-lg-4 col-md-6 application-card  mt-2 ${formik.values.applications.includes(application.id) ? 'selected-application-card' : ''}`}
              onClick={() => handleSelectApplication(application.id)}
              style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})` }}
            > */}
              {/* <Card.Body>
                <Card.Title className='text-light'>{application.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-secondary">{application.description}</Card.Subtitle> */}
                {/* <Card.Link href={application.website_url}>Take a Look</Card.Link> */}
              {/* </Card.Body>
            </Card>
          ))}
        </div> */}
        {/* Accept Terms */}
        <div className='fv-row mb-8 mt-5'>
          <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('acceptTerms')}
            />
            <span>
              I Accept the{' '}<span
                          data-toggle="tooltip" data-placement="bottom" title="Terms & Conditions"
                          className='text-primary cursor-pointer'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_1'
                        >
                        Terms & Conditions
                        </span>.
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
  <div className="modal-dialog modal-lg"> 
    <div className="modal-content">
      <div className="modal-header bg-primary text-white">
        <h5 className="modal-title">Terms & Conditions</h5>
        <button
          type="button"
          className="btn-close text-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div
        className="modal-body p-4"
        style={{ maxHeight: '500px', overflowY: 'auto' }} 
      >
        <p>
          <strong>Terms and Conditions</strong>
          <br />
          <br />
          Welcome to Wriko (“Portal,” “Platform,” “we,” “us,” or “our”). These Terms and Conditions (“Terms”) govern your access to and use of the Wriko portal, which provides access to various applications and services (“Applications”). By accessing, subscribing to, or using Wriko, you agree to be bound by these Terms. If you do not agree, please refrain from using the Platform.
          <br />
          <br />
          ---
          <br />
          <br />
          <strong>1. Acceptance of Terms</strong>
          <br />
          By accessing or using Wriko, you confirm that: <br />
          1.1. You are at least 18 years of age or the legal age of majority in your jurisdiction. <br />
          1.2. You have read, understood, and agree to comply with these Terms.
          <br />
          <br />
          ---
          <br />
          <br />
          <strong>2. Description of Services</strong>
          <br />
          2.1. Portal Services: Wriko provides a centralized platform where users can subscribe to and manage various Applications. <br />
          2.2. Applications: Each Application available on Wriko may have its own terms and conditions, privacy policies, and fees, which you must accept and comply with separately. <br />
          2.3. Subscription Plans: Users may choose from free or paid subscription plans, which may vary based on the Application or service.
          <br />
          <br />
          ---
          <br />
          <br />
          <strong>3. User Accounts</strong>
          <br />
          3.1. Registration: To access Wriko and its Applications, you must create an account by providing accurate and complete information. <br />
          3.2. Account Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities conducted under your account. <br />
          3.3. Unauthorized Access: Notify Wriko immediately if you suspect unauthorized access to your account.
          <br />
          <br />
          ---
          <br />
          <br />
          <strong>4. User Conduct</strong>
          <br />
          By using Wriko, you agree not to: <br />
          4.1. Use the Platform for unlawful purposes. <br />
          4.2. Impersonate any person or entity, or falsely state your affiliation with any person or entity. <br />
          4.3. Interfere with or disrupt the Platform’s functionality or servers. <br />
          4.4. Copy, modify, or distribute any content from Wriko without authorization.
          <br />
          <br />
          ---
          <br />
          <br />
          <strong>5. Subscriptions and Payments</strong>
          <br />
          5.1. Fees: Certain Applications may require subscription fees. You agree to pay all applicable fees as outlined at the time of subscription. <br />
          5.2. Billing: Wriko uses third-party payment processors. By providing your payment information, you authorize us to charge your chosen payment method. <br />
          5.3. Refund Policy: Refunds are subject to the policies of individual Applications or Wriko.
          <br />
          <br />
          ---
          <br />
          <br />
          <strong>6. Contact Information</strong>
          <br />
          If you have any questions or concerns about these Terms, please contact us at: Email:
          <br />
          <br />
          ---
          <br />
          <br />
          By using Wriko, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
          <br />
          <br />
          ---
          <br />
          <br />
          Make sure this draft is tailored to Wriko’s specific requirements and consult legal counsel for compliance with relevant regulations in your operating jurisdictions.
        </p>
      </div>
      {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button type="button" className="btn btn-primary">
          Agree
        </button>
      </div> */}
    </div>
  </div>
</div>


      <div className='d-flex justify-content-between mx-15 mt-5'>
        <button
          type='button'
          className='btn btn-primary btn-md prev-button disabled'
          onClick={handlePrevButton}>
          Previous
        </button>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary d-none submit-button'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress d-block' >
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <button
          type='button'
          className='btn btn-primary next-button btn-md'
          disabled={!formik.values.firstname || !formik.values.lastname || !formik.values.email || !formik.values.password || !formik.values.changepassword}
          onClick={handleNextButton}>
          Next
        </button>
      </div>

    </form>
  )
}
