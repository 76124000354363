/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const authData = (() => {
    const data = localStorage.getItem('kt-auth-react-v');
    try {
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error parsing kt-auth-react-v from localStorage:', error);
      return null;
    }
  })();
  const access_token: string = authData?.access_token || null;
  const navigate=useNavigate();
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
  <div className='menu-item m-3'>
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-50px me-2'>
          <div className='symbol symbol-100px position-relative'>
            {authData.user.picture ? (
              <img
                src={authData.user.picture}
                alt='Profile Pic'
                className='img-fluid rounded-circle shadow-sm'
              />
            ) : (
              <div
              className='symbol symbol-fixed position-relative'
              style={{
                width: '60px', // Set the outer container width
                height: '60px', // Set the outer container height
                borderRadius: '50%', // Make the container circular
                overflow: 'hidden', // Ensure the image doesn't exceed container bounds
              }}
            >
              <img
                src={toAbsoluteUrl('/media/avatars/blank.png')}
                alt='Metornic'
                style={{
                  width: '100%', // Image takes full width of the container
                  height: '100%', // Image takes full height of the container
                  objectFit: 'cover', // Ensure the image is cropped to fit inside
                }}
              />
            </div>            
            )}
          </div>
        </div>
      </div>
      <div className='d-flex flex-column'>
          <div className='fw-bold fs-5 text-dark'>
            {authData.user.full_name}
            <span className='badge badge-light-success ms-2'>Online</span>
          </div>
          <span className='text-muted fs-7'>
            {authData.user.email}
          </span>
        </div>
    </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/change-password'} className='menu-link px-5'>
          Change Password
        </Link>
      </div>

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <a onClick={() => {
            // logout(access_token);
            navigate('/logout');
          }} 
          className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
